@import "@/styles/globals.scss";
@font-face {
  font-family: acumin-pro-wide;
  src: url(https://use.typekit.net/af/601890/00000000000000003b9acb39/27/l?subset_id=2&fvd=n1&v=3) format('woff2'),
    url(https://use.typekit.net/af/601890/00000000000000003b9acb39/27/d?subset_id=2&fvd=n1&v=3) format('woff'),
    url(https://use.typekit.net/af/601890/00000000000000003b9acb39/27/a?subset_id=2&fvd=n1&v=3) format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-wide;
  src: url(https://use.typekit.net/af/9c8f5a/00000000000000003b9acb3b/27/l?subset_id=2&fvd=n2&v=3) format('woff2'),
    url(https://use.typekit.net/af/9c8f5a/00000000000000003b9acb3b/27/d?subset_id=2&fvd=n2&v=3) format('woff'),
    url(https://use.typekit.net/af/9c8f5a/00000000000000003b9acb3b/27/a?subset_id=2&fvd=n2&v=3) format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-wide;
  src: url(https://use.typekit.net/af/1b8691/00000000000000003b9acb3d/27/l?subset_id=2&fvd=n3&v=3) format('woff2'),
    url(https://use.typekit.net/af/1b8691/00000000000000003b9acb3d/27/d?subset_id=2&fvd=n3&v=3) format('woff'),
    url(https://use.typekit.net/af/1b8691/00000000000000003b9acb3d/27/a?subset_id=2&fvd=n3&v=3) format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-wide;
  src: url(https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/l?subset_id=2&fvd=n4&v=3) format('woff2'),
    url(https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/d?subset_id=2&fvd=n4&v=3) format('woff'),
    url(https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/a?subset_id=2&fvd=n4&v=3) format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-wide;
  src: url(https://use.typekit.net/af/cc64d9/00000000000000003b9acb41/27/l?subset_id=2&fvd=n5&v=3) format('woff2'),
    url(https://use.typekit.net/af/cc64d9/00000000000000003b9acb41/27/d?subset_id=2&fvd=n5&v=3) format('woff'),
    url(https://use.typekit.net/af/cc64d9/00000000000000003b9acb41/27/a?subset_id=2&fvd=n5&v=3) format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro;
  src: url(https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/l?subset_id=2&fvd=i4&v=3) format('woff2'),
    url(https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/d?subset_id=2&fvd=i4&v=3) format('woff'),
    url(https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/a?subset_id=2&fvd=i4&v=3) format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-extra-condensed;
  src: url(https://use.typekit.net/af/f2b2eb/00000000000000003b9acb1a/27/l?subset_id=2&fvd=n4&v=3) format('woff2'),
    url(https://use.typekit.net/af/f2b2eb/00000000000000003b9acb1a/27/d?subset_id=2&fvd=n4&v=3) format('woff'),
    url(https://use.typekit.net/af/f2b2eb/00000000000000003b9acb1a/27/a?subset_id=2&fvd=n4&v=3) format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/44ed7f/00000000000000003b9acb04/27/l?subset_id=2&fvd=n2&v=3) format('woff2'),
    url(https://use.typekit.net/af/44ed7f/00000000000000003b9acb04/27/d?subset_id=2&fvd=n2&v=3) format('woff'),
    url(https://use.typekit.net/af/44ed7f/00000000000000003b9acb04/27/a?subset_id=2&fvd=n2&v=3) format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/c64ee1/00000000000000003b9acb06/27/l?subset_id=2&fvd=n3&v=3) format('woff2'),
    url(https://use.typekit.net/af/c64ee1/00000000000000003b9acb06/27/d?subset_id=2&fvd=n3&v=3) format('woff'),
    url(https://use.typekit.net/af/c64ee1/00000000000000003b9acb06/27/a?subset_id=2&fvd=n3&v=3) format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/l?subset_id=2&fvd=n4&v=3) format('woff2'),
    url(https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/d?subset_id=2&fvd=n4&v=3) format('woff'),
    url(https://use.typekit.net/af/4b28f4/00000000000000003b9acb08/27/a?subset_id=2&fvd=n4&v=3) format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/b1bf07/00000000000000003b9acb0a/27/l?subset_id=2&fvd=n5&v=3) format('woff2'),
    url(https://use.typekit.net/af/b1bf07/00000000000000003b9acb0a/27/d?subset_id=2&fvd=n5&v=3) format('woff'),
    url(https://use.typekit.net/af/b1bf07/00000000000000003b9acb0a/27/a?subset_id=2&fvd=n5&v=3) format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/7bebb5/00000000000000003b9acb0c/27/l?subset_id=2&fvd=n6&v=3) format('woff2'),
    url(https://use.typekit.net/af/7bebb5/00000000000000003b9acb0c/27/d?subset_id=2&fvd=n6&v=3) format('woff'),
    url(https://use.typekit.net/af/7bebb5/00000000000000003b9acb0c/27/a?subset_id=2&fvd=n6&v=3) format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/l?subset_id=2&fvd=n7&v=3) format('woff2'),
    url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/d?subset_id=2&fvd=n7&v=3) format('woff'),
    url(https://use.typekit.net/af/69b3c5/00000000000000003b9acb0e/27/a?subset_id=2&fvd=n7&v=3) format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/d15900/00000000000000003b9acb05/27/l?subset_id=2&fvd=i2&v=3) format('woff2'),
    url(https://use.typekit.net/af/d15900/00000000000000003b9acb05/27/d?subset_id=2&fvd=i2&v=3) format('woff'),
    url(https://use.typekit.net/af/d15900/00000000000000003b9acb05/27/a?subset_id=2&fvd=i2&v=3) format('opentype');
  font-weight: 200;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: acumin-pro-condensed;
  src: url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/l?subset_id=2&fvd=i7&v=3) format('woff2'),
    url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/d?subset_id=2&fvd=i7&v=3) format('woff'),
    url(https://use.typekit.net/af/d99dcd/00000000000000003b9acb0f/27/a?subset_id=2&fvd=i7&v=3) format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: auto;
}
